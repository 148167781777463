import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import Layout from '../components/layout'
import SEO from '../components/seo'
import RichTextRenderer from '../components/rich-text-renderer'
import {
  SectionHeader,
  Section,
  SectionContainer,
  SectionDescription,
  SectionContent
} from '../styles/styles'
import ImageGallery from '../components/gallery'

const FoodContainer = styled.div`
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
  margin-top: 15px;
  margin-bottom: 40px;
`
const FoodCard = styled.div`
  background-color: rgba(27, 28, 29, 0.95);
  box-shadow: 0px 0px 2px #00000080;
  padding: 40px;
  @media (max-width: 480px) {
    padding: 20px;
  }
  /* display: flex;
  flex-direction: column;
  align-items: center; */
  /* justify-content: space-around; */
  border-radius: 5px;
  > div,
  p {
    flex: 1;
  }
`

const Taproom = ({ data }) => {
  const {
    title,
    backgroundImage,
    galleryTitle,
    gallerySubtitle,
    galleryDescription,
    galleryImages,
    foodSectionTitle,
    foodOptions,
    more,
    taproomInfo
  } = data.contentfulTaproomPage
  return (
    <Layout bgImage={backgroundImage}>
      <SEO title="Taproom" />
      <h1>{title}</h1>
      <SectionHeader>{galleryTitle}</SectionHeader>
      <Section>
        <SectionContainer>
          <SectionDescription>
            <h4>{gallerySubtitle}</h4>
            <p dangerouslySetInnerHTML={{ __html: galleryDescription }}></p>
            <RichTextRenderer json={taproomInfo.json} />
          </SectionDescription>
          <SectionContent>
            <ImageGallery images={galleryImages} />
          </SectionContent>
        </SectionContainer>
      </Section>
      <SectionHeader>{foodSectionTitle}</SectionHeader>
      <FoodContainer>
        {foodOptions.map(f => (
          <FoodCard>
            <div
              style={{
                height: 200,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: 20
              }}
            >
              <a
                target="_blank"
                rel="noopener noreferrer"
                style={{ display: 'block', textAlign: 'center' }}
                href={f.externalLink}
              >
                <img
                  src={f.image.fluid.src}
                  style={{ maxHeight: 180, maxWidth: '80%' }}
                  alt={f.title}
                />
              </a>
            </div>
            <p>{f.description}</p>
          </FoodCard>
        ))}
      </FoodContainer>
      <SectionHeader>More</SectionHeader>
      <Section>
        <RichTextRenderer json={more.json} />
      </Section>
    </Layout>
  )
}

export default Taproom

export const query = graphql`
  query TaproomQuery {
    contentfulTaproomPage {
      backgroundImage {
        fluid(maxWidth: 1024) {
          src
          srcSet
          sizes
          srcSetWebp
          srcWebp
          base64
          aspectRatio
        }
      }
      foodSectionTitle
      galleryDescription
      gallerySubtitle
      galleryTitle
      galleryImages {
        fixed(width: 200) {
          width
          height
          src
          srcSet
        }
        fluid {
          src
          srcSet
          sizes
          srcSetWebp
          srcWebp
          base64
          aspectRatio
        }
      }
      currentBeers {
        id
        title
        kind
        ibu
        srm
        abv
      }
      more {
        json
      }
      taproomInfo {
        json
      }
      title
      foodSectionTitle
      foodOptions {
        title
        description
        displayOrder
        externalLink
        image {
          fluid {
            src
            srcSet
            sizes
            srcSetWebp
            srcWebp
            base64
            aspectRatio
          }
        }
      }
    }
  }
`
